import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Chip, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import dayjs from 'dayjs';
import DashboardPanelContainer from '../Components/PanelDashboard/DashboardPanelContainer';
import SideBar from '../Components/SideBar';
import SettingsPanelContainer from '../Components/PanelSettings/SettingsPanelContainer';
import PanelObjectsContainer from '../Components/PanelObjects/PanelObjectsContainer';
import { useEffect } from 'react';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CachedIcon from '@mui/icons-material/Cached';

const Dashboard = ({ measurementsRequest, dataRequest, transfersRequest, checkServerRequest }) => {
    const alldata = useSelector(state => state.data.data)
    const allMeasurements = useSelector(state => state.measurements.measurements)
    const applicantId = [...new Set(alldata.map((c) => c.applicant_id))]
    const applicantName = [...new Set(alldata.map((c) => c.applicant_name))]
    const applicantBin = [...new Set(alldata.map((c) => c.applicant_bin))]
    const applicant = { applicant_id: applicantId[0], applicant_name: applicantName[0], applicant_bin: applicantBin[0] }
    const checkingTime = useSelector(state => state.measurements.checkingTime)

    const nowDate = dayjs(new Date())
    //const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
    const timeServer = (nowDate.$H * 60) + nowDate.$m
    //const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
    const dateServer = nowDate.$y + '-' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '-' + (nowDate.$D > 9 ? nowDate.$D : '0' + (nowDate.$D))

    const [mobileOpen, setMobileOpen] = useState(false);
    //const [filterDate, setFilterDate] = useState(date);

    const dispatch = useDispatch()

    const setCheckingTime = (data) => {
        dispatch({ type: 'MEASUREMENTS/SET_MEASUREMENTS_CHEKING_TIME', data })
    }

    const [Component, setComponent] = useState({ title: "Панель состояния", content: <DashboardPanelContainer /> })

    const navigate = useNavigate()

    //определяем последние замеры
    useEffect(() => {
        const tanks = alldata.map((c) => ({ id: c.id, name: c.name, tank_id: c.tank_id, object_id: c.object_id }))
        const first = tanks.map((t) => ({
            ...t,
            timeIncome: allMeasurements.find((m) => m.tank_id === t.tank_id) ? [allMeasurements.find((m) => m.tank_id === t.tank_id)]
                : []
        })).map((timeCheking) => ({
            ...timeCheking,
            timeIncome: timeCheking.timeIncome.length > 0 ? {
                status: timeCheking.timeIncome[0].status, date: timeCheking.timeIncome[0].measurement_date.slice(0, 10), time: (parseInt(timeCheking.timeIncome[0].measurement_date.slice(11, 13)) * 60) + parseInt(timeCheking.timeIncome[0].measurement_date.slice(14, 16))
            } : [],
            timeNow: { date: dateServer, time: timeServer - 11 }
        }))
            .map((timeSet) => ({
                ...timeSet,
                in: timeSet.timeIncome.date === timeSet.timeNow.date ?
                    timeSet.timeIncome.time > timeSet.timeNow.time ? true : false
                    : false
            }))

        setCheckingTime(first)
        //console.log(first);
    }, [allMeasurements])


    useEffect(() => {
        dataRequest()
        transfersRequest()
        measurementsRequest()
        checkServerRequest()
    }, [])

    const reLoadData = () => {
        dataRequest()
        transfersRequest()
        measurementsRequest()
        checkServerRequest()
    }

    useEffect(() => {
        let timer

        timer = setInterval(() => {
            transfersRequest()
            measurementsRequest()
            checkServerRequest()
            console.log("Запрос");

        }, 10000);

        // очистка интервала
        return () => clearInterval(timer);
    });


    const setContent = (index) => {

        switch (index) {
            case 0:
                return setComponent({ title: "Панель состояния", content: <DashboardPanelContainer /> });

            case 1:
                return setComponent({ title: "Объекты", content: <PanelObjectsContainer /> });

            case 2:
                return setComponent({ title: "Настройки", content: <SettingsPanelContainer /> });



            default:
                return setComponent({ title: "Панель состояния", content: <DashboardPanelContainer /> });
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    //проверка доступа АЗС\API
    const azs = checkingTime.filter((inT) => inT.in === false)
    const api = checkingTime.filter((inT) => inT.timeIncome.status === false)
    

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>

            <IconButton
                color="inherit"
                aria-label="open drawer"

                onClick={handleDrawerToggle}
                sx={{ mr: 1, top: '16px', height: '32px', width: '32px', left: '18px', border: 1, borderColor: '#636b74', borderRadius: '8px', p: '5px', display: { sm: 'none' } }}
            >
                <MenuIcon sx={{ width: '20px', height: '20px', color: '#636b74' }} />
            </IconButton>

            <SideBar applicant={applicant} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} setContent={setContent} />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 0.5, pt: 1.5 }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        mr: 5
                    }}
                >
                    <Typography variant='h2' fontSize={30} fontWeight={700} sx={{ lineHeight: '1.33', letterSpacing: '-0.025em' }}>
                        {Component.title}
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <IconButton aria-label="delete" color='secondary' sx={{border: 1, p: 0.5}} onClick={reLoadData} >
                            <CachedIcon fontSize="inherit" />
                        </IconButton>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: '#07139e' }} />
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            Доступность клиента АЗС и сервера OilTrack:
                        </Typography>
                        <Chip variant="outlined" color={azs.length > 0 ? "error" : "success"} icon={<LocalGasStationOutlinedIcon />} label="АЗС" />
                        <Chip variant="outlined" color={api.length > 0 ? "error" : "success"} icon={<CloudDoneOutlinedIcon />} label="API OilTrack" />
                    </Stack>
                </Stack>
                <Divider sx={{ borderColor: '#07139e' }} />
                {Component.content}
            </Box>

        </Box>)
    // }
}

export default Dashboard