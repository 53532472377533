//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import PanelObjects from './PanelObjects';
import { measurementsRequest } from '../../Redux/measurementReducer';

class PanelObjectsContainer extends React.PureComponent {

    render() {
        return (<PanelObjects                     
            measurementsRequest={this.props.measurementsRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {  
    measurementsRequest   
    
})(PanelObjectsContainer)
