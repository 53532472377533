import { Container, Grid, Typography } from '@mui/material';
import React from 'react';

const Error = () => {

    return <Container maxWidth="100%" sx={{ mt: 8, p: 0 }}>
        <Grid container  >
            <Grid item sx={{ height: '100%' }} >
                <Typography variant='h2' fontSize={30} fontWeight={700} sx={{color: 'red'}} >
                    Ошибка!!!
                </Typography>
            </Grid>
        </Grid>
    </Container>
}

export default Error;
