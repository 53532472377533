import { Button, Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import React from 'react';
import MeasurementsTable from '../Tables/MeasurementsTable';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import TransfersTable from '../Tables/TransfersTable';
import { useState } from 'react';

const HomePage = ({ measurementsRequest, dataRequest, transfersRequest }) => {
    //localStorage.setItem('finupkzuserchat', JSON.stringify(data.user)) 

    const allMeasurements = useSelector(state => state.measurements.measurements)
    const allTransfers = useSelector(state => state.transfer.transfers)

    const [openFormInfo, setOpenFormInfo] = useState(false)
    const [component, setComponent] = useState(<></>)

    const setInfoComponent = (item) => {
        setComponent(item)
        setOpenFormInfo(true)
    }

    return <div style={{ marginTop: '8px' }} >
        <Grid container  >
            <Grid item sx={{ height: '100%' }} >
                {/* <Button variant="outlined" onClick={measurementsRequest} >Загрузить</Button> */}
                <MeasurementsTable allMeasurements={allMeasurements} setInfoComponent={setInfoComponent} setOpenFormInfo={setOpenFormInfo} />
                <TransfersTable allTransfers={allTransfers} setInfoComponent={setInfoComponent} setOpenFormInfo={setOpenFormInfo} />
                
                <Dialog
                    open={openFormInfo}
                    onClose={() => setOpenFormInfo(false)}
                    scroll='body'
                    sx={{p: 0, borderRadius: 5}}
                >
                    {/* <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px', minWidth: '300px' }} color="#07139e" id="customized-dialog-title">
                        Информация о компании
                    </DialogTitle> */}

                    {/* <IconButton
                        aria-label="close"
                        onClick={() => setOpenFormInfo(false)}
                        color='error'
                        sx={{
                            position: 'absolute',
                            right: 1,
                            top: 1,
                            p: '5px'
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton> */}

                    <DialogContent sx={{p: 0, borderRadius: 3}}>
                        {component}
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    </div>
}

export default HomePage;
