import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_DATA = 'DATA/SET_DATA';

//Started props
let initialState = {
    data: [],
    backdrop: false,

};

//Reducers functions SET_LOGOUT
const dataReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                data: action.data
            }     

        default:
            return state;
    }
}

//Action Creators functions

const setData = (data) => {
    return { type: SET_DATA, data }
}

// //Thunk functions

export const dataRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.getData()
        if (data) {
            dispatch(setData(data))           
        }
    }
}

export default dataReducer;