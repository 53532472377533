import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { useEffect } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useSelector } from 'react-redux';
import CardMeasurement from '../Components/Cards/CardMeasurement';
import { ruRU } from '@mui/x-data-grid/locales';
import { Typography } from '@mui/material';

function EditToolbar(props) {

    return (
        <GridToolbarContainer>
            <Typography color='secondary' textAlign="center" variant="h6" sx={{ml: 1}} >ЗАМЕРЫ</Typography>
            <GridToolbar showQuickFilter={props.showQuickFilter} csvOptions={props.csvOptions} />
        </GridToolbarContainer>
    );
}


function MeasurementsTable({ allMeasurements, setInfoComponent, setOpenFormInfo }) {

    const [rows, setRows] = React.useState(allMeasurements);
    const alldata = useSelector(state => state.data.data)

    useEffect(() => {
        setRows(allMeasurements.map((meas) => ({ ...meas, object_name: alldata.filter(comp => comp.tank_id === meas.tank_id).map((obj) => obj.object_name)[0], tank_name: alldata.filter(comp => comp.tank_id === meas.tank_id).map((obj) => obj.name)[0] })))
    }, [allMeasurements, alldata])

    const onRowClick = React.useCallback(
        (params) => {
            setInfoComponent(<CardMeasurement setOpenFormInfo={setOpenFormInfo} row={params.row} />)
        },
        [setInfoComponent, setOpenFormInfo],
    );

    const columns = [
        {
            field: 'id',
            headerName: '№',
            width: 100,
            editable: false
        },
        {
            field: 'object_name',
            headerName: 'Объект',
            width: 120,
            editable: false,

        },
        {
            field: 'tank_name',
            headerName: '№ резервуара',
            width: 150,
            editable: false,
        },
        {
            field: 'volume',
            headerName: 'Объем',
            width: 80,
            editable: false,
        },
        {
            field: 'level',
            headerName: 'Уровень',
            width: 80,
            editable: false,
        },
        {
            field: 'mass',
            headerName: 'Вес',
            width: 80,
            editable: false,
        },
        {
            field: 'density',
            headerName: 'Плотность',
            width: 80,
            editable: false,
        },
        {
            field: 'measurement_date',
            headerName: 'Дата замера',
            width: 200,
            editable: false,
        },
        {
            field: 'oil_product_type',
            headerName: 'Топливо',
            width: 80,
            editable: false,
        },
        {
            field: 'temperature',
            headerName: 'Температура',
            width: 80,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Отправлен',
            width: 80,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => params.value ? <CheckCircleRoundedIcon color='success' /> : <ErrorRoundedIcon color='error' />,
        },
    ];

    return (
        <Box
            sx={{
                height: '45vh',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                flex: 1
            }}
        >
            <Box sx={{ height: '100% !important', width: "100% !important" }}>
                <DataGrid
                    rows={rows}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    disableDensitySelector
                    autoPageSize
                    density="compact"
                    onRowClick={onRowClick}
                    sx={{ fontSize: "12px", width: "inherit" }}
                    columns={columns}
                    slots={{ toolbar: EditToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: {
                                fileName: 'Замеры резервуаров',
                                delimiter: ';',
                                utf8WithBom: true,
                            }
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

export default MeasurementsTable;