//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { addUserChatRequest, authUserRequest, setNeUserKeyRequest } from '../../Redux/authReducer';

class LoginFormContainer extends React.PureComponent {

    render() {
        return (<LoginForm   
            authUserRequest={this.props.authUserRequest}    
            addUserChatRequest={this.props.addUserChatRequest}
            setNeUserKeyRequest={this.props.setNeUserKeyRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
    authUserRequest,
    addUserChatRequest,
    setNeUserKeyRequest
})(LoginFormContainer)
