import axios from 'axios';

const instance = axios.create({
    baseURL: "http://192.168.10.9:80/tank",
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }

});

const instanceSattings = axios.create({
    baseURL: "http://192.168.10.9:80",
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }

});

const instanceCheckUser = axios.create({
    baseURL: "https://petrolsoft.kz/",
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }

});

const pools = {
    pool: {
        user: "postgres",
        password: "Rm1006@@",   
        host: "localhost",
        port: 5432,
        database: "petrol_soft",
    }
}

const notInternet = (error) => {
    //window.location.href = '/error'
    console.log("нет инета");
    console.log(error)
}

const ConnectToServer = {

    getMeasurements() {
        return instance.post('/allmeasurements', pools)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },

    getData() {
        return instance.post('/alldata', pools)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },

    getTransfers() {
        return instance.post('/alltransfers', pools)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },
    getServerStatus() {
        return instanceSattings.get('/serverstatus')
            .then(response => response.data)
            .catch(error => notInternet(error))
    },
    setServerWork() {
        return instanceSattings.post('/send-stop', pools)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },
    getAuthUser(obj) {
        return instance.post('/checkuser', obj)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },
    checkUser(obj) {
        return instanceCheckUser.post('check_id_user.php', obj)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },
    setNewUserKey(obj) {
        return instance.post('/setkye', obj)
            .then(response => response.data)
            .catch(error => notInternet(error))
    },
}

    

export default ConnectToServer;