//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { measurementsRequest } from '../Redux/measurementReducer';
import { dataRequest } from '../Redux/dataReducer';
import { transfersRequest } from '../Redux/transferReducer';
import { checkServerRequest } from '../Redux/workServerReducer';

class DashboardContainer extends React.PureComponent {

    render() {
        return (<Dashboard                     
            measurementsRequest={this.props.measurementsRequest}
            dataRequest={this.props.dataRequest}
            transfersRequest={this.props.transfersRequest}
            checkServerRequest={this.props.checkServerRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
    measurementsRequest, 
    dataRequest, 
    transfersRequest,
    checkServerRequest
})(DashboardContainer)
