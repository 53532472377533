import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_TRANSFERS = 'TRANSFERS/SET_TRANSFERS';

//Started props
let initialState = {
    transfers: [],
    backdrop: false,

};

//Reducers functions SET_LOGOUT
const transferReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_TRANSFERS:
            return {
                ...state,
                transfers: action.transfers
            }     

        default:
            return state;
    }
}

//Action Creators functions

const setTransfers = (data) => {
    return { type: SET_TRANSFERS, transfers: data }
}

// //Thunk functions

export const transfersRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.getTransfers()
        if (data) {
            dispatch(setTransfers(data))           
        }
    }
}

export default transferReducer;