import * as React from 'react';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';
import CardGasStation from '../Cards/CardGasStation';
import { Grid } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width:'95%', margin: 0, padding: 0}}

        >
            {value === index && (
                <Box sx={{ p: 0.5}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const PanelObjects = ({measurementsRequest}) => {
    const [value, setValue] = React.useState(0);
    const allMeasurements = useSelector(state => state.measurements.measurements)
    const alldata = useSelector(state => state.data.data)
    //console.log(allMeasurements);

    // const applicantId = [...new Set(alldata.map((c) => c.applicant_id))]
    // const applicantName = [...new Set(alldata.map((c) => c.applicant_name))]
    // const applicantBin = [...new Set(alldata.map((c) => c.applicant_bin))]
    // const applicant = { applicant_id: applicantId[0], applicant_name: applicantName[0], applicant_bin: applicantBin[0] }

    const objects = [...new Set(alldata.map((c) => c.object_id))].map((obj) => ({ object_id: obj, object_name: [...new Set(alldata.filter((d) => d.object_id === obj).map((c) => c.object_name))][0] }))
    const tanks = alldata.map((c) => ({ id: c.id, name: c.name, tank_id: c.tank_id, object_id: c.object_id }))
    
    const first = tanks.map((t)=>({...t, measurement: allMeasurements.find((m)=>m.tank_id===t.tank_id)?[allMeasurements.find((m)=>m.tank_id===t.tank_id)]: []}))

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider', minWidth: '110px' }}
            >
                {objects.map((obj) =>
                    <Tab key={obj.object_name} sx={{p:0}} icon={<LocalGasStationOutlinedIcon sx={{ bgcolor: red[500], color: 'white' }} />} iconPosition="start" label={obj.object_name} {...a11yProps(0)} />
                )}
            </Tabs>
            {objects.map((obj, index) =>
                <TabPanel key={obj.object_id+index} value={value} index={index} >
                    <Grid container spacing={2} >
                    {first.map((t)=>t.object_id===obj.object_id? <Grid key={t.id} item xs={12} sm={3} > <CardGasStation tank={t} measurementsRequest={measurementsRequest} /></Grid>:<div key={t.id} ></div>)}
                    </Grid>
                </TabPanel>
            )}
        </Box>
    );
}

export default PanelObjects