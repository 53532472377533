//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import HomePage from './HomePage';
import { measurementsRequest } from '../Redux/measurementReducer';
import { dataRequest } from '../Redux/dataReducer';
import { transfersRequest } from '../Redux/transferReducer';


class HomePageContainer extends React.PureComponent {

    render() {
        return (<>            
                <HomePage 
                measurementsRequest={this.props.measurementsRequest}
                dataRequest={this.props.dataRequest}
                transfersRequest={this.props.transfersRequest}
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
        
    }
};

export default connect(mapStateToProps, {  
    measurementsRequest,
    dataRequest,
    transfersRequest
})(HomePageContainer)
