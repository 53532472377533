import React from 'react';
import {
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";


const InfoMeasurement = ({ row }) => {

  return (
    <Grid container spacing={1} sx={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <Stack spacing={1} divider={<Divider sx={{ borderColor: '#07139e' }} flexItem />}>
          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              Резервуар №
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.tank_base_id}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              tank_id:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.tank_id}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              Продукт:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.oil_product_type}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>

            <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              Объем:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.volume} - {row.volume_unit_type}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              Уровень:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.level} - {row.level_unit_type}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              Вес:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.mass} - {row.mass_unit_type}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              Плотность:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.density}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              Температура:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.temperature}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              Дата замера:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.measurement_date}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              Статус:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.status ? "Отправлен в OilTrack" : "Не отправлен в OilTrack"}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              package_id:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.package_id}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
              request_guid:
            </Typography>
            <Typography textAlign='left' sx={{ m: 'auto' }} >
              {row.request_guid}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default InfoMeasurement