import * as React from 'react';
import PropTypes from 'prop-types';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import {    
    Avatar,
    Drawer,
    Box,
    Divider,    
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Typography,   
} from '@mui/material';
import logo from '../Img/logo-horizontalNEW.png'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

function SideBar({ mobileOpen, handleDrawerToggle, setContent, window1, applicant }) {
    
    const [selectedIndex, setSelectedIndex] = useState(0);
    const authUser = useSelector(state => state.auth.authUser)
    
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index)
        setContent(index)
    };

    const navigate = useNavigate();

    const handleExitUser = () => {
        localStorage.clear("petrolsoftuser")
        navigate("/")
        window.location.reload()
    };


    const drawer = (<>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '48px', p: 1, boxShadow: 1 }}>
            <Avatar variant="rounded" src={logo} sx={{ width: 220, height: 55 }} />
        </Box>

        <div style={{ padding: '16px', paddingTop: '4px' }}>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="small">
                    <BusinessRoundedIcon />
                </IconButton>
                <Typography sx={{ fontWeight: '600' }} color='secondary' level="title-lg">{applicant.applicant_name}</Typography>
            </Box>

            <Divider sx={{ borderColor: '#07139e' }} />
            <List component="nav" sx={{ mt: '25px' }}>
                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0)}
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <DashboardRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                Панель состояния
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>     
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <LocalGasStationIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                Объекты
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>             
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <SettingsApplicationsOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                Настройки
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>   
            </List>
        </div>
        <Divider sx={{ borderColor: '#07139e', mt: 'auto', ml: '16px', mr: '16px' }} />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexGrow: 0, p: '16px' }}>

            <Avatar
                variant="outlined"
                sx={{ width: '30px', height: '30px' }}
                
            />
            <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >Пользователь</Typography>
                <Typography sx={{ fontSize: '0.75rem', fontWeight: '500' }} >{authUser.user_name}</Typography>
            </Box>
            <IconButton onClick={handleExitUser} size="sm" variant="plain" color="neutral" title="Выход">
                <LogoutRoundedIcon />
            </IconButton>
        </Box>
    </>
    );

    const container = window1 !== undefined ? () => window1().document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, bgcolor: 'background.paper' }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

SideBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SideBar;