import React from 'react';
import {
  Grid,
  Stack,
  Typography,
} from "@mui/material";


const InfoGasStaion = ({ row }) => {

  return (
    <Grid container spacing={1} sx={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <Stack spacing={1} >

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              tank_id:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.tank_id}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              Продукт:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.oil_product_type}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>

            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
              Объем:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.volume} - {row.volume_unit_type}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ minWidth: '82px' }} >
              Уровень:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.level} - {row.level_unit_type}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ minWidth: '82px' }} >
              Вес:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.mass} - {row.mass_unit_type}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ minWidth: '82px' }} >
              Плотность:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.density}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ minWidth: '82px' }} >
              Температура:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.temperature}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Typography textAlign='left' fontSize={14} color="#07139e" sx={{ minWidth: '82px' }} >
              Дата замера:
            </Typography>
            <Typography textAlign='left' fontSize={14} sx={{ m: 'auto' }} >
              {row.measurement_date.slice(0, 19).replace('T', '  ')}
            </Typography>
          </Stack>
                
        </Stack>
      </Grid>
    </Grid>
  );
}

export default InfoGasStaion