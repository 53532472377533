import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import InfoGasStaion from '../InfoBlock/InfoGasStaion';
import { Stack } from '@mui/material';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import InfoEmpty from '../InfoBlock/InfoEmpty';
import { useSelector } from 'react-redux';

export default function CardGasStation({ tank, measurementsRequest }) {
    const checkingTime = useSelector(state => state.measurements.checkingTime)

    return (
        <Card sx={{ backgroundColor: '#90caf963', borderRadius: 3, position: 'relative', borderColor: '#07139e', border: 1 }}>
            <CardHeader
                sx={{ backgroundColor: 'white', p: 1.5 }}
                avatar={
                    <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                        <GasMeterIcon />
                    </Avatar>
                }
                action={
                    <IconButton onClick={measurementsRequest} aria-label="settings">
                        <CachedOutlinedIcon color='secondary' />
                    </IconButton>
                }
                title={
                    <Stack direction="row" spacing={0.5}>
                        <Typography fontSize={14} textAlign='left' color="#07139e" >
                            Резервуар:
                        </Typography>
                        <Typography fontSize={14} textAlign='left'  >
                            {tank.name}
                        </Typography>
                    </Stack>
                }
            // subheader={
            //     <Stack direction="row" spacing={0.5}>
            //         <Typography fontSize={13} textAlign='left' color="#07139e" >
            //             Object_id:
            //         </Typography>
            //         <Typography fontSize={13} textAlign='left' >
            //             276168963548000000
            //         </Typography>
            //     </Stack>
            // }
            />
            <CardContent sx={{ pb: 0 }} >
                {tank.measurement.length > 0 ?
                    <InfoGasStaion row={tank.measurement[0]} />
                    : <InfoEmpty />}
            </CardContent>
            <CardActions disableSpacing>
                <Typography textAlign='left' >
                    Связь:
                </Typography>
                <IconButton color={checkingTime.filter((t) => t.tank_id === tank.tank_id)[0].in ? 'success' : 'error'} aria-label="add to favorites">
                    <BrowserUpdatedIcon />
                </IconButton>
                <IconButton color={checkingTime.filter((t) => t.tank_id === tank.tank_id)[0].timeIncome.status ? 'success' : 'error'} aria-label="share">
                    <BackupOutlinedIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}
