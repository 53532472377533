import  {applyMiddleware, combineReducers, createStore} from 'redux';
import { thunk } from 'redux-thunk';
import authReducer from './authReducer';
import measurementReducer from './measurementReducer';
import dataReducer from './dataReducer';
import transferReducer from './transferReducer';
import workServerReducer from './workServerReducer';

let reducers = combineReducers({      
    auth: authReducer,
    measurements: measurementReducer,
    data: dataReducer,
    transfer: transferReducer,
    workServer: workServerReducer
    
});

//подключение devtools chrome - выявление ошибок через браузер хром
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));
let store = createStore(reducers, applyMiddleware(thunk));
//window.store = store;// глобальная переменная для проверки store
export default store;