import React from 'react';
import {
  Grid,
  Typography,
} from "@mui/material";


const InfoEmpty = () => {

  return (
    <Grid container spacing={1} sx={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        
            <Typography textAlign='center' color="#07139e" sx={{ m: 'auto' }} >
              По данному резервуару измерений НЕТ!
            </Typography>
           
      </Grid>
    </Grid>
  );
}

export default InfoEmpty