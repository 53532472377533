import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Stack } from '@mui/material';
import InfoTransfer from '../InfoBlock/InfoTransfer';

export default function CardTransfers({setOpenFormInfo, row}) {
    return (
        <Card sx={{ backgroundColor: '#90caf963', borderRadius: 3, position: 'relative' }}>
            <CardHeader
                sx={{ backgroundColor: 'white', p:1.5 }}
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <LocalGasStationOutlinedIcon />
                    </Avatar>
                }
                action={
                    <IconButton onClick={()=>setOpenFormInfo(false)} aria-label="settings">
                        <CloseRoundedIcon color='error' />
                    </IconButton>
                }
                title={
                    <Stack direction="row" spacing={0.5}>
                        <Typography fontSize={14} textAlign='left' color="#07139e" >
                            АЗС №
                        </Typography>
                        <Typography fontSize={14} textAlign='left'  >
                        {row.object_name}
                        </Typography>
                    </Stack>
                }
                subheader={
                    <Stack direction="row" spacing={0.5}>
                        <Typography fontSize={13} textAlign='left' color="#07139e" >
                            id транзакции: 
                        </Typography>
                        <Typography fontSize={13} textAlign='left' >
                        {row.id}
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <InfoTransfer row={row} />
            </CardContent>
        </Card>
    );
}
