import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { useEffect } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import CardTransfers from '../Components/Cards/CardTransfers';
import { ruRU } from '@mui/x-data-grid/locales';

function EditToolbar(props) {

    return (
        <GridToolbarContainer>
            <Typography color='secondary' textAlign="center" variant="h6" sx={{ml: 1}} >ПЕРЕМЕЩЕНИЯ</Typography>
            <GridToolbar showQuickFilter={props.showQuickFilter} csvOptions={props.csvOptions} />
        </GridToolbarContainer>
    );
}

function TransfersTable({ allTransfers, setInfoComponent, setOpenFormInfo }) {

    const [rows, setRows] = React.useState(allTransfers);
    const alldata = useSelector(state => state.data.data)

    useEffect(() => {

        setRows(allTransfers.map((meas) => ({ ...meas, object_name: alldata.filter(comp => comp.tank_id === meas.tank_id).map((obj) => obj.object_name)[0], tank_name: alldata.filter(comp => comp.tank_id === meas.tank_id).map((obj)=>obj.name)[0] })))
    }, [allTransfers, alldata])

    const onRowClick = React.useCallback(
        (params) => {            
             setInfoComponent(<CardTransfers setOpenFormInfo={setOpenFormInfo} row={params.row} />)
        },
        [setInfoComponent, setOpenFormInfo],
      );

    const columns = [
        {
            field: 'id',
            headerName: '№',
            width: 100,
            editable: false
        },
        {
            field: 'object_name',
            headerName: 'Объект',
            width: 120,
            editable: false,

        },
        {
            field: 'tank_name',
            headerName: '№ резервуара',
            width: 150,
            editable: false,
        },
        {
            field: 'volume_start',
            headerName: 'Объем',
            width: 0,
            editable: false,
            hideSortIcons: true,
            align: 'left',
            headerAlign: 'center',
           
            
        },
        {
            field: 'volume_end',
            headerName: 'Объем',
            width: 80,
            editable: false,
            hideSortIcons: true,
            align: 'left',
            headerAlign: 'center',
            renderCell: (params) => 
                <div>
                    <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0.5, mt: 0.2, pb: 0, lineHeight: '1' }} color='green' textAlign="center" >{params.row.volume_start}</Typography>
                    <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} color='secondary' textAlign="center" >{params.row.volume_end}</Typography>
                </div>
            
        },
        {
            field: 'level_end',
            headerName: 'Уровень',
            width: 80,
            editable: false,
            hideSortIcons: true,
            align: 'left',
            headerAlign: 'center',
            renderCell: (params) => 
                <div>
                    <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0.5, mt: 0.2, pb: 0, lineHeight: '1' }} color='green' textAlign="center" >{params.row.level_start}</Typography>
                    <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} color='secondary' textAlign="center" >{params.row.level_end}</Typography>
                </div>
            
        },
        {
            field: 'mass_end',
            headerName: 'Вес',
            width: 80,
            editable: false,
            hideSortIcons: true,
            align: 'left',
            headerAlign: 'center',
            renderCell: (params) => 
                <div>
                    <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0.5, mt: 0.2, pb: 0, lineHeight: '1' }} color='green' textAlign="center" >{params.row.mass_start}</Typography>
                    <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} color='secondary' textAlign="center" >{params.row.mass_end}</Typography>
                </div>
            
        },
        {
            field: 'end_date',
            headerName: 'Дата поставки',
            width: 200,
            editable: false,
            hideSortIcons: true,
            align: 'left',
            headerAlign: 'center',
            renderCell: (params) => 
                <div>
                    <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mb: 0.5, mt: 0.2, pb: 0, lineHeight: '1' }} color='green' textAlign="center" >{params.row.start_date}</Typography>
                    <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} color='secondary' textAlign="center" >{params.row.end_date}</Typography>
                </div>
            
        },
        {
            field: 'oil_product_type',
            headerName: 'Топливо',
            width: 80,
            editable: false,
        },
        {
            field: 'operation_type',
            headerName: 'Тип операции',
            width: 80,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Отправлен',
            width: 80,
            editable: false, 
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => params.value ? <CheckCircleRoundedIcon color='success' /> : <ErrorRoundedIcon color='error' />,
        },
    ];

    return (
        <Box
            sx={{
                height: '45vh',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                flex: 1
            }}
        >
            <Box sx={{ height: '100% !important', width: "100% !important" }}>
                <DataGrid
                    rows={rows}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    disableDensitySelector
                    autoPageSize
                    onRowClick={onRowClick}
                    density="compact"
                    sx={{ fontSize: "12px", width: "inherit" }}
                    columns={columns}
                    columnVisibilityModel={{
                        volume_start: false,
                      }}
                    slots={{ toolbar: EditToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: {
                                fileName: 'Перемещения резервуаров',
                                delimiter: ';',
                                utf8WithBom: true,
                            }
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

export default TransfersTable;