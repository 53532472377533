import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  //Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../Img/logo-horizontalNEW.png'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const myHelper = {
  email: {
    required: "Заполните поле",
    pattern: "Не корректный формат e-mail"
  },
  password: {
    required: "Заполните поле"
  }
};

const LoginForm = ({ addUserPwdRequest, authUserRequest, addUserChatRequest, setNeUserKeyRequest }) => {
  const applicant = useSelector(state => state.auth.authUser)
  const driver = useSelector(state => state.auth.driver)
  const openCollapse = useSelector(state => state.auth.openCollapseError)
  const errorAuth = useSelector(state => state.auth.errorAuth)
  const newKey = useSelector(state => state.auth.newKey)

  const [user, setUser] = useState({login: '', password: ''})

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
console.log(applicant);

  useEffect(()=>{
    if (applicant.user_key === null||applicant.user_name === null||applicant.user_pass === null) {
      console.log("Пользователь не существует");
      alert("Пользователь не существует!!!")
    } else {
      addUserChatRequest({
        login: applicant.user_name,
        password: applicant.user_pass,  
        key: applicant.user_key, 
        hard: driver
      })      
    }
  }, [applicant, driver])

  useEffect(()=>{
    
      if (newKey.length > 0){
        localStorage.setItem('petrolsoftuser', JSON.stringify({...applicant, user_key: newKey}))
        setNeUserKeyRequest({
          user_key: newKey,
          user_name: applicant.user_name          
        })
        navigate('/')
      } else {
        
      }
    }, [newKey])
  

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });


  const handleOnSubmit = (evt) => {    
    authUserRequest(evt)
    setUser(evt)
  };

  return (
    <Card sx={{ borderRadius: 3, position: 'relative', borderColor: '#07139e', border: 1, maxWidth: "412px", margin: "150px auto" }}>
      <CardContent sx={{ pb: 0 }} >

        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar variant="rounded" src={logo} sx={{ width: 220, height: 55 }} />
          </Stack>
          <Divider color='#808080' flexItem />
          <Typography variant="h5" gutterBottom component="div" color='secondary' sx={{ textAlign: 'center', my: 3 }}>
            Авторизация
          </Typography>
          <Grid item xs={12} >
            <Collapse in={openCollapse}>
              <Alert severity="error">{errorAuth}</Alert>
            </Collapse>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="login"
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    fullWidth
                    size="small"
                    label="Логин: e-mail"
                    error={error !== undefined}
                    helperText={error ? myHelper.email[error.type] : ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="password"
                    fullWidth
                    size="small"
                    label="Пароль"
                    error={error !== undefined}
                    helperText={error ? myHelper.password[error.type] : ""}
                  />
                )}
              />
            </Grid>


            <Grid item xs={12}>
              <Button size="large" color="secondary" variant="outlined" type="submit" fullWidth>Войти</Button>
            </Grid>
            {/* <Grid item xs={12}>
            <Button size="small" type="button">Забыли пароль?</Button>
            <Divider color='#07139e' flexItem />
          </Grid> */}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default LoginForm