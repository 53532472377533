import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_WORK = 'SERVERWORK/SET_WORK';

//Started props
let initialState = {
    serverWork: false,
    backdrop: false,

};

//Reducers functions SET_LOGOUT
const workServerReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_WORK:
            return {
                ...state,
                serverWork: action.serverWork
            }     

        default:
            return state;
    }
}

//Action Creators functions

const setWork = (data) => {
    return { type: SET_WORK, serverWork: data }
}

// //Thunk functions

export const checkServerRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.getServerStatus()
        if (data) {
            dispatch(setWork(data))           
        }
    }
}

export const setServerRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.setServerWork()
        if (data) {
            dispatch(setWork(data))           
        }
    }
}

export default workServerReducer;