//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import DashboardPanel from './DashboardPanel';

class DashboardPanelContainer extends React.PureComponent {

    render() {
        return (<DashboardPanel                     
                    
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
   
})(DashboardPanelContainer)
