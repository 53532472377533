//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import SettingsPanel from './SettingsPanel';
import { setServerRequest } from '../../Redux/workServerReducer';

class SettingsPanelContainer extends React.PureComponent {

    render() {
        return (<SettingsPanel                     
                setServerRequest={this.props.setServerRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
    setServerRequest
})(SettingsPanelContainer)
