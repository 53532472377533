import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_MEASUREMENTS = 'MEASUREMENTS/SET_MEASUREMENTS';
const SET_MEASUREMENTS_CHEKING_TIME = 'MEASUREMENTS/SET_MEASUREMENTS_CHEKING_TIME';

//Started props
let initialState = {
    measurements: [],
    backdrop: false,
    checkingTime: [],
};

//Reducers functions SET_LOGOUT
const measurementReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_MEASUREMENTS:
            return {
                ...state,
                measurements: action.measurements
            }

        case SET_MEASUREMENTS_CHEKING_TIME:
            return {
                ...state,
                checkingTime: action.data
            }

        default:
            return state;
    }
}

//Action Creators functions

const setMeasurements = (data) => {
    return { type: SET_MEASUREMENTS, measurements: data }
}

// //Thunk functions

export const measurementsRequest = () => {
    return async (dispatch) => {
        const data = await ConnectToServer.getMeasurements()
        if (data) {
            dispatch(setMeasurements(data))
        }
    }
}

export default measurementReducer;