import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_USER = 'AUTH/SET_USER';
const SET_DRIVER = 'AUTH/SET_DRIVER';
const SET_NEW_KEY = 'AUTH/SET_NEW_KEY';

//Started props
let initialState = {
    openFormNewAccountant: false,
    openFormNewClient: false,
    openFormNewFNO: false,
    openCollapseError: false,
    errorAuth: '',
    newKey: '',
    authUser: {
        id: 0,
    },
    backdrop: false,
    driver: ''
};

//Reducers functions SET_LOGOUT
const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                authUser: action.data
            }

        case SET_DRIVER:
            return {
                ...state,
                driver: action.data
            }

        case SET_NEW_KEY:
            return {
                ...state,
                newKey: action.data
            }


        default:
            return state;
    }
}

//Action Creators functions

const setUser = (data) => {
    return { type: 'AUTH/SET_USER', data }
}

const setDriver = (data) => {
    return { type: 'AUTH/SET_DRIVER', data }
}

const setNewKey = (data) => {
    return { type: 'AUTH/SET_NEW_KEY', data }
}


// //Thunk functions

export const authUserRequest = (obj) => {
    return async (dispatch) => {

        const data = await ConnectToServer.getAuthUser(obj)
        
        if (data) {
            if (data.success === 1) {
                dispatch(setUser(data.applicant))
                dispatch(setDriver(data.hard))
                // const pairDay = Math.ceil((new Date(parseInt(data.authUser.datePay.slice(6, 10)), parseInt(data.authUser.datePay.slice(3, 5)) - 1, parseInt(data.authUser.datePay.slice(0, 2)) + 1) - new Date()) / (1000 * 3600 * 24));
                // dispatch(setDayPay(pairDay))
                // if (pairDay > 0) {
                //     dispatch(setAuthUser(data.authUser))

                // } else {
                //     dispatch({ type: 'OLDAPPLICATION/SET_BACKDROP', data: false })
                //     dispatch(setUserPay(false))
                // }

            } else {
                console.log(data)
                //выход

                //dispatch(toggleIsFetching(false))
            }
        }
    }
}

export const addUserChatRequest = (obj) => {  
    console.log(obj);
      
    return async (dispatch) => {
        const data = await ConnectToServer.checkUser(obj)
        console.log(data);
        
        if (data.success === 1) {            
            dispatch(setNewKey(data.userKey))            
        } else {
            alert("Не правельный ключ!")
            dispatch(setNewKey(''))
        }
    }
}

export const setNeUserKeyRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setNewUserKey(obj)
        if (data.success === 1) {
            console.log('key update');
        } else {
            console.log('error');
            
        }
    }
}

// export const newClientRequest = (obj) => {
//     return async (dispatch) => {
//         const data = await ConnectToServer.sendNewClient(obj)
//         if (data) {
//             console.log(data);
//             dispatch(setOpenFormClient(false))
//         }
//     }
// }

// export const newFNORequest = (obj) => {
//     return async (dispatch) => {
//         const data = await ConnectToServer.sendNewFNO(obj)
//         if (data) {
//             console.log(data);
//             dispatch(setOpenFormFNO(false))
//         }
//     }
// }

export default authReducer;