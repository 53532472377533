import { Box, Chip, Divider, IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import LeakAddOutlinedIcon from '@mui/icons-material/LeakAddOutlined';
import LeakRemoveOutlinedIcon from '@mui/icons-material/LeakRemoveOutlined';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const SettingsPanel = ({setServerRequest}) => {
    const serverWork = useSelector(state => state.workServer.serverWork)

    return (<Box
        component="main"
        sx={{ flexGrow: 1, p: 0.5, pt: 1.5 }}
    >
        <Stack
            direction="row"
            spacing={2}
            sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                mr: 5,
                pb: 0.5
            }}
        >
            <Typography variant='h6' fontWeight={700} color='secondary' >
                Вкл./Выкл. отправку данных на API OilTrack
            </Typography>
            
                 <IconButton aria-label="delete" color= {serverWork.server? 'success' : 'error'} sx={{border: 1}} onClick={setServerRequest} >
                          {serverWork.server? <LeakAddOutlinedIcon fontSize="inherit" /> : <LeakRemoveOutlinedIcon fontSize="inherit" />}  
                </IconButton>
                {/* <Divider orientation="vertical" flexItem sx={{ borderColor: '#07139e' }} />
                <Typography variant="subtitle1" color="primary" gutterBottom>
                    Доступность клиента АЗС и сервера OilTrack:
                </Typography>
                <Chip variant="outlined"  icon={<LocalGasStationOutlinedIcon />} label="Сервер Запущен" /> */}
            
        </Stack>
        <Divider sx={{ borderColor: '#07139e' }} />
       
    </Box>)

}

export default SettingsPanel