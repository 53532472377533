import * as React from 'react';
import HomePageContainer from '../../Home/HomePageContainer';

const DashboardPanel = () => {


    return (<>
        <HomePageContainer />
    </>)

}

export default DashboardPanel